<template>
  <img class="hero-image" :src="require(`@/assets/habana_brisa_hero.jpg`)" />
  <div class="content-container">
    <h3>Welcome to CicciMotorsports.com</h3>
    <p>
      Cicci Motorsports is a professional race team that uses all of the available tools to build a
      racing partnership that will make business sense for you. We have proven experience in
      creating B2B opportunities that will be a key to your success. Our ability to define multiple
      ways to build your brand, and bottom line, will allow you to take full advantage of…the Power
      of Motorsports, the Loyalty of Race fans, PR &amp; social media, at-track opportunities to
      develop employee morale and customer loyalty, plus the true adrenaline rush of being a part of
      our amazing sport on TV and radio. Together we can design a complete package that will bring
      you to Victory Lane.
    </p>

    <h3>Why Cicci Motorsports?</h3>

    <p>
      We understand that it takes a team effort to win, not just on the track but off the track as
      well. Sadly, most teams do not understand this. Most teams will just slap your brand on the
      side of their car and tell you good luck with your marketing. Or they think because they have
      a big name driver that you should be grateful to just be on the car.
    </p>

    <p>
      We here at Cicci Motorsports believe that track side marketing, and off track marketing should
      support each other. We will work with you and your marketing team to design a NASCAR supported
      marketing plan that is fully customized to fit your needs and the needs of your customers.
      This is VERY important to us…why? Because your customers are our fans.
    </p>

    <p><strong>Services Offered -</strong></p>

    <ul>
      <li>Custom Hospitality</li>
      <li>Direct fan engagement</li>
      <li>Weekly Press Releases &amp; Media Interviews</li>
      <li>Storefront marketing</li>
      <li>Trade Show marketing</li>
      <li>Garage &amp; Pit Passes per race</li>
      <li>Digital marketing &amp; custom SEO support</li>
      <li>Social Media activation</li>
      <li>Raffles &amp; Giveaways</li>
      <li>Custom Fundraisers</li>
    </ul>
  </div>
</template>

<style scoped>
.hero-image {
  width: 100%;
}
</style>
