<template>
  <main class="content-container">
    <h2>About the crew</h2>

    <h3>Frank Cicci</h3>
    <p><strong>Cicci Motorsports</strong> was founded by a champion team owner, who is a NASCAR veteran. A native of Elmira, New York, Frank Cicci began his career as a NASCAR car owner in 1979 on the dirt tracks of upstate New York and claimed his first ever feature victory in that same season.</p>

    <p>In 1987 Frank moved south with his driver and started a very successful NASCAR career. 
  Frank is directly responsible for bringing Dollar General, GNC, and Sport Clips to NASCAR.  All of which were very successful racing programs.</p>
    <div class="accolades">
      <ul>
        <li><strong>19 Career NASCAR victories</strong></li>
        <li>Veteran team owner</li>
        <li>Started racing career in 1985</li>
        <li>Most starts in Nationwide Series (752)</li>
        <li>Oldest team in Nationwide Series</li>
      </ul>

      <ul>
       <li>3 Track qualifying records</li>
       <li>14 Pole Positions</li>
       <li>282 Top Ten Finishes</li>
       <li>139 Top Five Finishes</li>
      </ul>
    </div>

    <h3>Mark Daniel</h3>
    <p>Mark Daniel has over 30 years of business development experience directly in NASCAR. Mark’s day to day dealings involve speaking with individuals interested in a marketing platform or any activation that can bolster their Brand, Organization or Destination.  Mark’s talent lets him locate partnerships who are given a proven marketing plan which includes advertising, entertaining, sponsorships, hospitality and other revenue producing opportunities surrounding NASCAR. Mark at the same time maintains awareness of competition in NASCAR.</p>

    <p>Mark has secured Sponsorship (Partnerships) with the Development of Sales and Marketing Programs that resulted in Promotional Value for: Habana Brisa, Cheaptickets.com, CLC Custom Leather, Little River Casino Resort, DealTaker.com, Nature&apos;s-Broom, New Balance, Orbitz.com, Bearclaw Coffee Co. along with Raceday center condos, each have been a valuable Partner for race teams with placement on the race car and team hauler, uniforms and other logo placements at race events throughout the race seasons.</p>

    <p>You will find Mark at the race track taking care of his Partners.</p>

    <p><strong>To contact Mark:</strong> <br><br>
    Email: <a href="mailto:Markjdaniel50@gmail.com">Markjdaniel50@gmail.com</a><br>
    Cell: <a href="tel:(269)998-4169">(269)998-4169</a></p>
  </main>
</template>

<style scoped>
.accolades {
  display: grid;
  grid-template-columns: 50% 50%;
}
ul {
  display: grid;
  grid-column: auto; 
}
</style>
