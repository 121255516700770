<script setup>
import LogoImage from '@/components/LogoImage';
const year = new Date().getFullYear();
</script>

<template>
  <div class="nav-container">
    <LogoImage />
    <nav>
      <router-link to="/">Home</router-link> | <router-link to="/news">News</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
  </div>
  <router-view />
  <footer>
    <p class="copyright">Copyright &copy; {{ year }} CicciMotorsports. All Rights Reserved</p>
    <nav>
      <router-link to="/">Home</router-link> | <router-link to="/news">News</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/contact">Contact</router-link>
    </nav>
  </footer>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;600;700&display=swap');

html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Cabin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  display: grid;
}

.content-container,
footer {
  display: grid;
  justify-self: center;
  width: 80%;
}

footer {
  border-top: 1px solid #ddd;
  grid-template-columns: 50% 50%;
  margin-top: 25px;
}

footer nav {
  grid-column: 2;
}

.copyright {
  grid-column: 1;
  margin: 0;
  padding: 30px;
}

.nav-container {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-template-rows: auto auto;
  justify-self: center;
  padding: 25px 0;
  width: 80%;
}

nav {
  padding: 30px;
  justify-self: end;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
  padding: 5px 8px;
  text-decoration: none;
}

nav a.router-link-exact-active {
  color: #ed1d28;
}

p {
  margin: 0 0 15px 0;
  line-height: 1.4;
}

@media (max-width: 600px) {
  .nav-container {
    grid-template-columns: 1fr;
  }
  nav {
    justify-self: center;
  }
}
</style>
