<template>
  <router-link to="/"><img :src="require(`@/assets/cicci_logo_dark.png`)" alt="Cicci Motorsports" title="Cicci Motorsports" /></router-link>
</template>

<style scoped>
img {
  width: 200px;
}

a:visited,
a:active,
a:hover {
color: #2c3e50;
}

@media (max-width: 600px) {
  a {
    text-align: center;
  }
}
</style>
