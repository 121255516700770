<script setup>
import axios from 'axios';
import { marked } from 'marked';

import { onMounted, reactive } from 'vue';

const state = reactive({
  releases: [],
});

onMounted(() => {
  axios
    .get(`https://ciccimotorsports-strapi.herokuapp.com/api/press-releases`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer eedf182ed39364a1c4ce043cdfbc4f6e80d41b5aee754c915d86bfa1ce5c320e67c4b3cad77f7cb9b9e974eb5f2240cdd030c09f133a904770f021857693ba3aa45c557422b4e699cd05cb9e7f804852c468193e2e35d9cba37139ad71628a54ec8b319b42ad4c05a10220bbb4d1d832dd9d0ec117fcb61e784af14c316867f2`,
      },
    })
    .then((d) => (state.releases = d.data.data))
    .catch((e) => console.log(e));
});
</script>
<template>
  <main class="content-container">
    <article>
      <h3 v-if="!state.releases.length">Loading...</h3>
      <section v-for="r in state.releases" :key="r">
        <h2>{{ r.attributes.Title }}</h2>
        <div v-html="marked(r.attributes.Content)"></div>
      </section>
    </article>
    <aside>
      CicciMotorsports.com is under construction.<br />
      More content to come.
    </aside>
  </main>
</template>

<style scoped>
main {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 70% auto;
}

article {
  text-align: left;
}
section {
  margin-bottom: 55px;
}
aside {
  margin-top: 20px;
  text-align: left;
}
</style>
